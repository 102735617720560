































































import Vue from "vue";
import GoogleMap from "./GoogleMap.vue";
export default Vue.extend({
  name: "ContactUs",
  components: {
    GoogleMap,
  },
  data: () => ({}),
});
